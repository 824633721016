import { useStore } from "vuex";
import asyncOperations from "./asyncOperations";
import { getCurrentInstance, isRef, reactive, ref, nextTick } from "vue";

export default ({ store, asyncOps, instance } = {}) => {
  instance = instance || getCurrentInstance();
  store = store || useStore();
  asyncOps = asyncOps || asyncOperations({}, store);

  const sendMessage = async (conversationId, content, senderId) => {
    if (!senderId) {
      senderId = store.getters["user/profile"].id;
    }
    let data = {
      content: content,
      SenderId: senderId,
      conversationUuid: conversationId,
    };

    let result = await asyncOps.asyncCall(
      `chat/conversation/${data.conversationUuid}/messages`,
      data,
      { method: "post" }
    );

    return result;
  };

  const startConversation = async (recipientUuids, content, senderId = false) => {
    if (!senderId) {
      senderId = store.getters["user/profile"].id;
    }

    let data = {
      Recipients: recipientUuids,
      content: content,
      SenderId: senderId,
    };

    let result = await asyncOps.asyncCall("chat/conversation", data, {
      method: "post",
    });

    return result;
  };

  const getVendorUserConversationUuid = async (vendorId = null, senderId = false) => {
    if (!senderId) {
      senderId = store.getters["user/profile"].id;
    }

    let result = await asyncOps.asyncCall(
      `chat/conversation/vendor/${vendorId}/${senderId}/uuid`,
      {},
      {
        method: "get",
      }
    );

    return result;
  };
  const messageVendor = async (content, vendorId = null, senderId = false) => {
    if (!senderId) {
      senderId = store.getters["user/profile"].id;
    }

    let data = {
      VendorId: vendorId,
      content: content,
      SenderId: senderId,
    };

    let result = await asyncOps.asyncCall(`chat/conversation/vendor/${vendorId}`, data, {
      method: "post",
    });

    return result;
  };

  const getSupportUserConversationUuid = async (senderId = false) => {
    if (!senderId) {
      senderId = store.getters["user/profile"].id;
    }

    let result = await asyncOps.asyncCall(
      `chat/conversation/support/${senderId}/uuid`,
      {},
      {
        method: "get",
      }
    );

    return result;
  };
  const messageSupport = async (content, senderId = false) => {
    if (!senderId) {
      senderId = store.getters["user/profile"].id;
    }

    let data = {
      content: content,
      SenderId: senderId,
    };

    let result = await asyncOps.asyncCall(`chat/conversation/support/${senderId}`, data, {
      method: "post",
    });

    return result;
  };

  return {
    chat: {
      startConversation,
      sendMessage,
      messageVendor,
      getVendorUserConversationUuid,
      getSupportUserConversationUuid,
      messageSupport,
    },
    startConversation,
    sendMessage,
    messageVendor,
    getVendorUserConversationUuid,
    getSupportUserConversationUuid,
    messageSupport,
  };
};
